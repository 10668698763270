import React, { useEffect, useState, useContext, useTransition } from "react";
import { Navigate, Route, Routes, useOutletContext, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import {MainPage} from './Pages/MainPage/MainPage.js'
import {Cart} from './Pages/Cart/Cart.js'
import {CartReturn} from './Pages/Cart/CartReturn.js'
import './App.css';
import { InStockPage } from "./Pages/InStock/InStock.js";
import { ComponentsPage, ComponentsDetailsPage } from "./Pages/Components/Components.js";
import { BYO } from "./Pages/BYO/BYO.js";
import { AboutUsPage } from "./Pages/AboutUsPage/AboutUs.js";
import { OurCreations } from "./Pages/OurCreations/OurCreations.js";
import { OwnersPage } from "./Pages/OwnersPage/OwnersPage.js";
import { PoliciesPage } from "./Pages/Policies/Policies.js";
import { CheckoutPage } from "./Pages/Checkout/Chekcout.js";
import { NavBar } from './Components/NavBar.js';
import {Footer} from './Components/Footer.js'
import { SideNavBar } from './Components/SideNavBar.js';
import { ScrollToTop } from "./Components/ScrollToTop.js";
import { backendURLContext } from '../src/index.js'
import { imgArray } from "./images/imagesArray.js";
import { Modal, ModalLoader } from "./Components/Modal.js";
import componentBgSwitches from './images/Switches_main_4.jpg'
import componentBgWE from './images/woodEssence_main_3.jpg'
import componentBgPCB from './images/PCB_main.jpg'
import componentBgplate from './images/plate_main.jpg'
import componentBgCable from './images/cable_main.jpg'
import componentBgkeycaps from './images/keycaps_main.jpg'
import componentBgstabs from './images/stabs_001_3.jpg'




// React multi carousel: https://www.npmjs.com/package/react-multi-carousel


function App() {
      const urls = useContext(backendURLContext);
      const backendURL = urls.backend;
      const frontendURL = urls.frontend;
      const [dataArray, setDataArray] = useState([]);
      const [filteredInStockKbdArray, setFilteredInStockKbdArray]=useState([]);
      const [filteredOurCreationkKbdArray, setFilteredOurCreationkKbdArray]=useState([])
      const [filteredWoodComponentArray, setFilteredWoodComponentArray]=useState([])
      const [appliedFilter, setAppliedFilter] = useState("none") 
      const [textDataArray, setTextDataArray] = useState([]);
      const [ready, setReady] = useState(false);
      const [cart, setCart] = useState({_itemCount: 0, _buildRequest:[]}); 
      const [displayLang, setDisplayLang] = useState("ENG");
      const [displayLangLong, setDisplayLangLong] = useState("English");
      const [displayCurr, setDisplayCurr] = useState("USD");
      const [modalText, setModalText] = useState("");
      const [modalLoaderText, setModalLoaderText] = useState("");
      const [checkoutBtnDisabled, setCheckoutBtnDisabled] = useState(true);
      const [updateShippingOptions, setUpdateShippingOptions] = useState(false);
      const [showLoader, setShowLoader] = useState(true);

      useEffect(() => {
            async function load(){
                  let existCart = localStorage.getItem("cart")? true:false;
                  if (existCart == false){ 
                        console.log("dans if cartExist, use effect du app.js");
                        // do nothing
                  } else {
                        let existingCart = await getLocalStorageData('cart');
                        console.log( "Dans use effet, local storage cart: " + existingCart, " uuid: " + existingCart._UUID);
                        setCart(existingCart);
                  }      
                  await getBackendData();
                  await getText();
                  await getLang();
                  await getCountry();
            }

            load();


      }, [])

      const getBackendData = async()=>{
            let imgTarget = {};
            // console.log("dans callBackEndGetInStockKBD, debut, backendurl: "+ backendURL);
            let updateSucessfull = false;
            await fetch(backendURL+"/content/getData")    
            .then((resObject)=>{
                  console.log("dans le .then");
                  if(!resObject.ok){
                        return resObject.text();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  console.log("dans app.js, a la fin du callBakendGetInStockKBD, ")
                  //merge with picture array
                  for (const section in res){
                        // console.log("section in res: " + JSON.stringify(res[section]));
                        for (const subArray in res[section]){
                              // console.log("section subarray" + JSON.stringify(res[section][subArray]));
                              res[section][subArray].map((product)=>{
                                    // console.log("dans map du res section, id " + product._id);
                                    imgTarget = imgArray.find((e)=> e._id == product._id);
                                    if(imgTarget === undefined){}//Do nothing, means there's no pictures in imgArray for that product
                                    else{
                                          product._coverPicture = imgTarget._coverPicture;
                                          product._pictures = imgTarget._pictures;
                                    } 
                              })
                        }
                  }
                  // console.log("a la fin du map"+ JSON.stringify(res));
                  setDataArray(res)
                  setFilteredInStockKbdArray(res.kbdArray.inStock)
                  setFilteredOurCreationkKbdArray(res.kbdArray.pastCreation)
                  setFilteredWoodComponentArray(res.componentsArray.woodEssence)
                  // setReady(true);
            }) 
      }

      const getText = async()=>{
            // console.log("dans callBackEndGetInStockKBD, debut, backendurl: "+ backendURL);
            let updateSucessfull = false;
            await fetch(backendURL+"/content/getText")    
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.text();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  setTextDataArray(res[0])
                  setReady(true);
                  // console.log("returned Text: " + JSON.stringify(res[0]));
            }) 
      }

      const getLang=async()=>{
            let existSelectedLang = localStorage.getItem("selectedLang")? true:false;
            let actualSelectedLang = localStorage.getItem("selectedLang");
            let browserLang = navigator.language.substring(0,2).toLowerCase();
            let selectedLang = "";
            let actualPath = window.location.pathname

            if (existSelectedLang){
                  setDisplayLang(actualSelectedLang)
            } else {
                  browserLang == "fr"? selectedLang ="FR": selectedLang ="ENG"
                  setDisplayLang(selectedLang)
                  localStorage.setItem('selectedLang', selectedLang);
            }
            //change URL for language management
            if(actualPath.substring(1,3)!="fr"){
                  if(actualSelectedLang == "FR"){
                        window.location.href = frontendURL + "/fr" + actualPath
                  }
            } else if(actualPath.substring(1,3)=="fr"){
                  if(actualSelectedLang == "ENG"){
                        setDisplayLang("FR");
                        setDisplayLangLong("Français")
                  }
            } 
      }

      const getCountry=async()=>{
            let existSelectedCurr = localStorage.getItem("selectedCurr")? true:false;
            let browserCountry = navigator.language.substring(3,5).toUpperCase();
            console.log("dans getCountry, langugae: " + navigator.language + "country: " + browserCountry);
            let selectedCurr = "";

            if (existSelectedCurr){
                  setDisplayCurr(localStorage.getItem("selectedCurr"))
            } else {
                  switch (browserCountry){
                        case "CA":
                              selectedCurr ="CAD";
                        break;
                        case "US":
                              selectedCurr ="USD";
                        break;
                        case "AT":
                        case "BE":
                        case "BG":
                        case "HR":
                        case "CY":
                        case "CZ":
                        case "DK":
                        case "EE":
                        case "FI":
                        case "FR":
                        case "DE":
                        case "GR":
                        case "HU":
                        case "IE":
                        case "IT":
                        case "LV":  
                        case "LT":
                        case "LU":
                        case "MT":
                        case "NL":
                        case "PL":
                        case "PT":
                        case "RO":
                        case "SK":  
                        case "SI":  
                        case "ES":  
                        case "SE":                                
                              selectedCurr ="EUR";
                        break;
                        default:
                              selectedCurr ="USD";

                  };
                  setDisplayCurr(selectedCurr)
                  localStorage.setItem('selectedCurr', selectedCurr);
            }
      }

      const getLocalStorageData= async(item)=>{
            let data = localStorage.getItem(item);
            // console.log("dans get local storage, item: " + item + " data: " + data + " data parsed: "
            // + JSON.parse(data));
            return (JSON.parse(data));
      }

      async function createCart(){
            let updateSucessfull = false;
            let newCart = {};
            console.log("dans createCart");
            await fetch(backendURL+"/cart/createCart", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'displayLang':displayLang,
                    'displayCurr':displayCurr
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              console.log("a la fin, dans le local storage: " + JSON.stringify(res));
              newCart = res;
            })
                
            return(newCart);
      }

      const addToCart=async(newItemData)=>{
            let localStorageCart = {};
            //Check if cart exist in local storage, if not call backend and store in local storage AND in state
            let existCart = localStorage.getItem("cart")? true:false;
            console.log("exist cart? " + existCart);
            if (existCart == false){ //call backend to get cart object
                  localStorageCart = await createCart();
            } else {
                  localStorageCart = await getLocalStorageData('cart');
                  console.log( "local storage cart: " + localStorageCart, " uuid: " + localStorageCart._UUID);
                  setCart(localStorageCart);
            }

            // call backend to add item
            let updateSucessfull = false;
            console.log("dans addToCart, cartUUID: " + localStorageCart._UUID);
            fetch(backendURL+"/cart/addItem", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': localStorageCart._UUID,
                    'newItem':newItemData
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
            })

            animateModal(textDataArray._modal.addedToCart[displayLang], 1800)
      }

      const removeFromCart=(cartUUID, itemID)=>{
            console.log("dans remove from cart " + itemID);

            // call backend to remove item
            let updateSucessfull = false;
            fetch(backendURL+"/cart/removeItem", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}), 
                  body: JSON.stringify({
                    'UUID': cartUUID,
                    'itemID':itemID
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              animateModal(textDataArray._modal.removeFromCart[displayLang], 1500)
            })

      }

      const modifyCart=(property, newData)=>{
            // call backend to modify cart
            let updateSucessfull = false;
            console.log("dans modifyCart, property: " + property + " new data: " + JSON.stringify(newData));
            fetch(backendURL+"/cart/modifyCart", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'property': property,
                        'newData':newData
                  })
                  })  
            .then((resObject)=>{
                  if(!resObject.ok){
                  return resObject.json();
                  } else {
                  updateSucessfull = true;
                  return resObject.json();
                  }
            })
            .then((res)=>{
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })
      }

      const setShippingOptions=async(selectedOption)=>{
            // call backend to set Shipping option
            console.log("dans set shipping option, selected option: " + selectedOption);
            let updateSucessfull = false;
            fetch(backendURL+"/cart/setShippingOption", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': cart._UUID,
                    'selectedOptionUUID':selectedOption,
                    'curr':displayCurr
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              setCheckoutBtnDisabled(false);
              document.querySelector('#checkoutBtn').innerHTML = textDataArray._cart.ckechkoutBtn[displayLang];
            })
      }

      const resetShippingOptions=async()=>{
            // call backend to set Shipping option
            console.log("dans resetShippingOptions: ");
            let updateSucessfull = false;
            fetch(backendURL+"/cart/resetShippingOption", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                    'UUID': cart._UUID,
                  })
                })  
            .then((resObject)=>{
              if(!resObject.ok){
                  return resObject.json();
              } else {
                updateSucessfull = true;
                return resObject.json();
              }
            })
            .then((res)=>{
              localStorage.setItem('cart',JSON.stringify(res));
              setCart(res);
              setCheckoutBtnDisabled(true);
              document.querySelector('#checkoutBtn').innerHTML = textDataArray._cart.ckechkoutBtnMsg[displayLang];
            })
      }

      const createBuild = async(UUID)=>{
            //call backend to push new build to cart       
            let updateSucessfull = false;
            console.log("dans createBuild, cartUUID: " + UUID);
            fetch(backendURL+"/cart/createBuild", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'UUID': UUID,
                  })
            })  
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.json();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  console.log("dans .then du createBuild");
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })

      }

      const updateBuild = async(target, newVal, group, targetID, detailID)=>{

            //call backend to update build     
            let updateSucessfull = false;
            console.log("dans updateBuild, cartUUID: " + cart._UUID + "backendURL:" +backendURL);
            fetch(backendURL+"/cart/updateBuild", {
                  method: 'POST',
                  headers: new Headers({'content-type':'application/json'}),
                  body: JSON.stringify({
                        'UUID': cart._UUID,
                        'target' : target,
                        'newVal': newVal, 
                        'group': group,
                        'targetID': targetID,
                        'detailID':detailID,
                        'lang':displayLang

                  })
            })  
            .then((resObject)=>{
                  if(!resObject.ok){
                        return resObject.json();
                  } else {
                        updateSucessfull = true;
                        return resObject.json();
                  }
            })
            .then((res)=>{
                  localStorage.setItem('cart',JSON.stringify(res));
                  setCart(res);
            })

      }

      const animateModal = (text, showDelay)=>{
            setModalText(text);
            let modal = document.querySelector('#Modal')
            modal.classList.remove('modalHiddenUP')
            setTimeout(()=>{
                  modal.classList.add('modalHiddenOpacity')
            }, showDelay)
            setTimeout(()=>{
                  modal.classList.add('modalHiddenUP')
            }, (showDelay+2000))
            setTimeout(()=>{
                  modal.classList.remove('modalHiddenOpacity')
            }, (showDelay+3000))
      }

      const animateModalLoader = (text)=>{
            setModalLoaderText(text);
            let modal = document.querySelector('#ModalLoader')
            modal.classList.remove('modalHiddenUP')
      }

      const clearModalLoader = ()=>{
            let modal = document.querySelector('#ModalLoader')
            modal.classList.add('modalHiddenUP')
      }

      const navigate = (e) =>{
            e.preventDefault(); // stop the browser from navigating to the destination URL.
            const hrefUrl = e.target.getAttribute('href');
            let pageToLoad = "";
            // alert("href dans navigate: " + hrefUrl);
            //define URL to load depending on selectedLang
            displayLang == "FR"? pageToLoad="/fr"+hrefUrl : pageToLoad=hrefUrl;
            
            // alert("Page to laod: " + pageToLoad);
            // document.getElementById('placeholder').innerHTML = load(pageToLoad);
            window.history.pushState({}, window.title, pageToLoad) // Update URL as well as browser history.
            window.location.href = frontendURL + pageToLoad
      }

  
      const filterKbdType=(targetType, btnId, sourceArray)=>{
          let filteredArray = [];
            //manage filter or not filtered
          if(targetType == appliedFilter){  //remove filter, managing a second clic on the filter button
            setFilteredInStockKbdArray(dataArray.kbdArray.inStock);
            setFilteredOurCreationkKbdArray(dataArray.kbdArray.pastCreation)
            setFilteredWoodComponentArray(dataArray.componentsArray.woodEssence)
            setAppliedFilter("none")
            //remove selected classes for CSS
            document.querySelector("#"+btnId).classList.remove("selected")
          } else {
            if(sourceArray=="inStock"){
                  filteredArray = dataArray.kbdArray.inStock.filter((kbd)=>kbd._caseType == targetType);
                  setFilteredInStockKbdArray(filteredArray);
            } else if(sourceArray=="pastCreation"){
                  filteredArray = dataArray.kbdArray.pastCreation.filter((kbd)=>kbd._caseType == targetType);
                  setFilteredOurCreationkKbdArray(filteredArray)
            } else if(sourceArray=="componentWood"){
                  filteredArray = dataArray.componentsArray.woodEssence.filter((component)=>component._caseType == targetType);
                  setFilteredWoodComponentArray(filteredArray)
            }
            setAppliedFilter(targetType)
            //remove class to both button before reapplying to the right one
            document.querySelector("#caselessFilterBtn").classList.remove("selected");
            document.querySelector("#caseFilterBtn").classList.remove("selected")

            document.querySelector("#"+btnId).classList.add("selected")
  
          }
      }

      return (
            <>
            {ready?
            <>
                  <ScrollToTop/>
                  <Helmet>
                        <meta
                              name="description"
                              content={textDataArray._headTag.appDescription[displayLang]}
                        />
                        <meta
                              name="keywords"
                              content="Keyboard, Mechanical, Mechanical Keyboard, Caseless Keyboard, Wooden Keyboard, Wood Keyboard, Hide Nothing, Custom Keyboard, Bespoke Keyboard"
                        />
                        <title>{textDataArray._headTag.appTitle[displayLang]}</title>
                  </Helmet>
                  <NavBar 
                        cartCount={cart._itemCount} 
                        animateModal={animateModal} 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        setDisplayLang={setDisplayLang}
                        setDisplayLangLong={setDisplayLangLong}
                        setDisplayCurr={setDisplayCurr}
                        displayCurr={displayCurr}
                        displayLangLong={displayLangLong}
                        resetShippingOptions={resetShippingOptions}
                        setUpdateShippingOptions = {setUpdateShippingOptions}
                        navigate = {navigate}
                        />
                <SideNavBar 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                        />
                  <aside>
                   <Modal 
                        text={modalText}
                  /> 
                  <ModalLoader 
                        text={modalLoaderText}
                  />
                  </aside>
                  <Routes>
                        <Route index element={<MainPage 
                              dataArray={dataArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                        />}/>
                        <Route exact path="/" element={<MainPage 
                        />}/>
                        <Route exact path="/cart" element={<Cart 
                              cart={cart} 
                              curr={displayCurr}
                              removeFromCart={removeFromCart}
                              setShippingOptions = {setShippingOptions}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              btnEnable={checkoutBtnDisabled}
                              animateModal={animateModal}
                              updateShippingOptions = {updateShippingOptions}
                              setUpdateShippingOptions = {setUpdateShippingOptions}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              />}
                        />
                        <Route exact path="cart/cartreturn" element={<CartReturn 
                              cartUUID={cart._UUID}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                        />}/>
                        <Route exact path="/checkout" element={<CheckoutPage 
                              cartUUID={cart._UUID}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              />} />
                        <Route exact path="/instock" element={<InStockPage  
                              dataArray = {filteredInStockKbdArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              filterKbdType={filterKbdType}
                              />}/>
                        <Route exact path="/aboutus" element={<AboutUsPage 
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              />}/>
                        <Route exact path="/components" element={<ComponentsPage 
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              />}/>
                        <Route exact path="/components/wood" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsWoodEssences.title[displayLang]}
                              subTitle ={textDataArray._componentsWoodEssences.subTitle[displayLang]} 
                              text = {textDataArray._componentsWoodEssences.text[displayLang]} 
                              id = "componentSubpage_WE"
                              dataArray = {filteredWoodComponentArray}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgWE}
                              altText={textDataArray._altTexts.componentsWE}
                              subPath={"wood"}
                              filterKbdType={filterKbdType}
                              />}

                        />
                        <Route exact path="/components/pcb" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPCB.title[displayLang]}
                              subTitle ={textDataArray._componentsPCB.subTitle[displayLang]} 
                              text = {textDataArray._componentsPCB.text[displayLang]} 
                              id = "componentSubpage_PCB"
                              dataArray = {dataArray.componentsArray.PCB}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgPCB}
                              altText={textDataArray._altTexts.componentsPCB}
                              subPath={"pcb"}
                              />}
                        />
                        <Route exact path="/components/plate" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPlate.title[displayLang]}
                              subTitle ={textDataArray._componentsPlate.subTitle[displayLang]} 
                              text = {textDataArray._componentsPlate.text[displayLang]} 
                              id = "componentSubpage_Plate"
                              dataArray = {dataArray.componentsArray.plate}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgplate}
                              altText={textDataArray._altTexts.componentsPlate}
                              subPath={"plate"}
                              />}
                        />
                        <Route exact path="/components/switches" element={<ComponentsDetailsPage 
                              title = {textDataArray._componentsSwitches.title[displayLang]}
                              subTitle ={textDataArray._componentsSwitches.subTitle[displayLang]} 
                              text = {textDataArray._componentsSwitches.text[displayLang]} 
                              id = "componentSubpage_Switches"
                              dataArray = {dataArray.componentsArray.switches}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgSwitches}
                              altText={textDataArray._altTexts.componentsSwitches}
                              subPath={"switches"}

                              />}
                              
                        />
                        <Route exact path="/components/keycaps" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsKeycaps.title[displayLang]}
                              subTitle ={textDataArray._componentsKeycaps.subTitle[displayLang]} 
                              text = {textDataArray._componentsKeycaps.text[displayLang]} 
                              id = "componentSubpage_Keycaps"
                              dataArray = {dataArray.componentsArray.keycaps}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgkeycaps}
                              altText={textDataArray._altTexts.componentsKeycaps}
                              subPath={"keycaps"}
                              />}/>
                        <Route exact path="/components/stabs" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsStabs.title[displayLang]}
                              subTitle ={textDataArray._componentsStabs.subTitle[displayLang]} 
                              text = {textDataArray._componentsStabs.text[displayLang]} 
                              id = "componentSubpage_Stabs"
                              dataArray = {dataArray.componentsArray.stabs}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgstabs}
                              altText={textDataArray._altTexts.componentsStabs}
                              subPath={"stabs"}
                              
                        />}/>
                        <Route exact path="/components/cable" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsCable.title[displayLang]}
                              subTitle ={textDataArray._componentsCable.subTitle[displayLang]} 
                              text = {textDataArray._componentsCable.text[displayLang]} 
                              id = "componentSubpage_Cable"
                              dataArray = {dataArray.componentsArray.cable}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgCable}
                              altText={textDataArray._altTexts.componentsCables}
                              subPath={"cable"}
                        />}/>
                        <Route exact path="/buildyourown" element={<BYO
                              updateBuild = {updateBuild}
                              createBuild = {createBuild}
                              createCart = {createCart}
                              getLocalStorageData = {getLocalStorageData}
                              setCart={setCart}
                              cartUUID={cart._UUID}
                              animateModal = {animateModal}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              />}/>
                        <Route exact path="/ourCreations" element={<OurCreations 
                              dataArray={filteredOurCreationkKbdArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              displayCurr={displayCurr}
                              filterKbdType={filterKbdType}
                              />}/>
                        <Route exact path="/ownersPage" element={<OwnersPage 
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              />}/>
                        <Route exact path="/policies" element={<PoliciesPage 
                              textDataArray={textDataArray}
                              displayLang="ENG"
                              />}/>
                  </Routes>
                  {/* FRENCH routes */}
                  <Routes> 
                        <Route exact path="/fr/" element={<MainPage 
                              dataArray={dataArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                        />}/>
                                 <Route exact path="/fr/cart" element={<Cart 
                              cart={cart} 
                              curr={displayCurr}
                              removeFromCart={removeFromCart}
                              setShippingOptions = {setShippingOptions}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              btnEnable={checkoutBtnDisabled}
                              animateModal={animateModal}
                              updateShippingOptions = {updateShippingOptions}
                              setUpdateShippingOptions = {setUpdateShippingOptions}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              />}
                        />
                        <Route exact path="/fr/cart/cartreturn" element={<CartReturn 
                              cartUUID={cart._UUID}
                              setCart={setCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                        />}/>
                        <Route exact path="/fr/checkout" element={<CheckoutPage 
                              cartUUID={cart._UUID}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              />} />
                        <Route exact path="/fr/instock" element={<InStockPage  
                              dataArray = {filteredInStockKbdArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              filterKbdType={filterKbdType}
                              />}/>
                        <Route exact path="/fr/aboutus" element={<AboutUsPage 
                              textDataArray={textDataArray}
                              displayLang='FR'
                              />}/>
                        <Route exact path="/fr/components" element={<ComponentsPage 
                              textDataArray={textDataArray}
                              displayLang='FR'
                              />}/>
                        <Route exact path="/fr/components/wood" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsWoodEssences.title[displayLang]}
                              subTitle ={textDataArray._componentsWoodEssences.subTitle[displayLang]} 
                              text = {textDataArray._componentsWoodEssences.text[displayLang]} 
                              id = "componentSubpage_WE"
                              dataArray = {filteredWoodComponentArray}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgWE}
                              altText={textDataArray._altTexts.componentsWE}
                              subPath={"wood"}
                              filterKbdType={filterKbdType}
                              />}

                        />
                        <Route exact path="/fr/components/pcb" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPCB.title[displayLang]}
                              subTitle ={textDataArray._componentsPCB.subTitle[displayLang]} 
                              text = {textDataArray._componentsPCB.text[displayLang]} 
                              id = "componentSubpage_PCB"
                              dataArray = {dataArray.componentsArray.PCB}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgPCB}
                              altText={textDataArray._altTexts.componentsPCB}
                              subPath={"pcb"}
                              />}
                        />
                        <Route exact path="/fr/components/plate" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsPlate.title[displayLang]}
                              subTitle ={textDataArray._componentsPlate.subTitle[displayLang]} 
                              text = {textDataArray._componentsPlate.text[displayLang]} 
                              id = "componentSubpage_Plate"
                              dataArray = {dataArray.componentsArray.plate}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgplate}
                              altText={textDataArray._altTexts.componentsPlate}
                              subPath={"plate"}
                              />}
                        />
                        <Route exact path="/fr/components/switches" element={<ComponentsDetailsPage 
                              title = {textDataArray._componentsSwitches.title[displayLang]}
                              subTitle ={textDataArray._componentsSwitches.subTitle[displayLang]} 
                              text = {textDataArray._componentsSwitches.text[displayLang]} 
                              id = "componentSubpage_Switches"
                              dataArray = {dataArray.componentsArray.switches}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgSwitches}
                              altText={textDataArray._altTexts.componentsSwitches}
                              subPath={"switches"}
                              />}
                              
                        />
                        <Route exact path="/fr/components/keycaps" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsKeycaps.title[displayLang]}
                              subTitle ={textDataArray._componentsKeycaps.subTitle[displayLang]} 
                              text = {textDataArray._componentsKeycaps.text[displayLang]} 
                              id = "componentSubpage_Keycaps"
                              dataArray = {dataArray.componentsArray.keycaps}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgkeycaps}
                              altText={textDataArray._altTexts.componentsKeycaps}
                              subPath={"keycaps"}
                              />}/>
                        <Route exact path="/fr/components/stabs" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsStabs.title[displayLang]}
                              subTitle ={textDataArray._componentsStabs.subTitle[displayLang]} 
                              text = {textDataArray._componentsStabs.text[displayLang]} 
                              id = "componentSubpage_Stabs"
                              dataArray = {dataArray.componentsArray.stabs}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgstabs}
                              altText={textDataArray._altTexts.componentsStabs}
                              subPath={"stabs"}                              
                        />}/>
                        <Route exact path="/fr/components/cable" element={<ComponentsDetailsPage 
                              title={textDataArray._componentsCable.title[displayLang]}
                              subTitle ={textDataArray._componentsCable.subTitle[displayLang]} 
                              text = {textDataArray._componentsCable.text[displayLang]} 
                              id = "componentSubpage_Cable"
                              dataArray = {dataArray.componentsArray.cable}
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              backgroundPicture={componentBgCable}
                              altText={textDataArray._altTexts.componentsCables}
                              subPath={"cable"}
                        />}/>
                        <Route exact path="/fr/buildyourown" element={<BYO
                              updateBuild = {updateBuild}
                              createBuild = {createBuild}
                              createCart = {createCart}
                              getLocalStorageData = {getLocalStorageData}
                              setCart={setCart}
                              cartUUID={cart._UUID}
                              animateModal = {animateModal}
                              animateModalLoader = {animateModalLoader}
                              clearModalLoader = {clearModalLoader}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              />}/>
                        <Route exact path="/fr/ourCreations" element={<OurCreations 
                              dataArray={filteredOurCreationkKbdArray} 
                              addToCart={addToCart}
                              textDataArray={textDataArray}
                              displayLang='FR'
                              displayCurr={displayCurr}
                              filterKbdType={filterKbdType}
                              />}/>
                        <Route exact path="/fr/ownersPage" element={<OwnersPage 
                              textDataArray={textDataArray}
                              displayLang='FR'
                              />}/>
                        <Route exact path="/fr/policies" element={<PoliciesPage 
                              textDataArray={textDataArray}
                              displayLang='FR'
                              />}/>
                  </Routes>

                  <Footer 
                        textDataArray={textDataArray}
                        displayLang={displayLang}
                  /> 
            </>
            :
            <Loader 
                  textDataArray={textDataArray}
                  displayLang={displayLang}
                  />
            }
            
            </>
      );
}

function Loader(){
      return(
            <></>
      )
}

// function Email(){   To delete, used only to build email frame and see the result in a webPage
//       return(
//             <div style={{color:'rgb(56,34,15)', display:'flex', flexDirection:'column', alignItems:'flex-start',
//             textAlign:'justify', width:'80%', marginLeft:'10%', padding:'2rem 2rem', backgroundColor:'rgb(219,193,172)'}}>
//                    <img src={require('./images/logoFinal_small.png')}  alt="HNK"/>
//                   <h2>Custom build request</h2>
//                   <p style={{alignSelf:'flex-start', fontSize:'1.5rem'}}>Hi,</p>
//                   <p style={{fontSize:'1.5rem'}} >Good news, we have received your request for a custom build. Our team will analyse it and
//                         get back to you as soon as possible. 
//                   </p>
//                   <p style={{fontSize:'1.5rem'}}>Thanks for reaching out and congradulation on your bold moove. Your exceptionnal keyboad is a few steps away. You can always head back to your cart and see the status of your request</p>
//                   <a href="https://wwww.localhost:3000" style={{alignSelf:'center', backgroundColor:'rgba(99,72,50,1)', 
//             color:'rgb(236,224,209)', textDecoration:'none', padding:'0.5rem 1rem', borderRadius:'5px', fontSize:'2rem'}} >My cart</a>
//                   <p style={{fontSize:'1.5rem'}}>In the meantime, if you have any questions, you can always contact us by email</p>
//                   <a href="mailto:info@hnkbd.ca" style={{alignSelf:'center', backgroundColor:'rgba(99,72,50,1)', 
//             color:'rgb(236,224,209)', textDecoration:'none', padding:'0.5rem 1rem', borderRadius:'5px', fontSize:'2rem'}} >Contact us</a>
//                   <p style={{fontSize:'1.5rem'}}>The HNK team</p>
//             </div>
//       )
// }

//Todo: donner a chaque ComponentsDetailPage un ID, et dans le component.css, venir ajuster l'image de fond

export {App}
