import React, { useState, useEffect } from 'react';
import './ourCreations.css';
import { CardContainer } from '../../Components/CardContainer.js';
import { Helmet } from 'react-helmet';

function OurCreations({dataArray, addToCart, textDataArray, displayLang, displayCurr,filterKbdType}){
    const tt = textDataArray._ourCreations;

    return(
        <main>
            <div id='ourCreationPage'>
                <Helmet>
                    <meta name="description" content={textDataArray._headTag.ourcreationDescription[displayLang]}/>
                    <title>{textDataArray._headTag.ourcreationTitle[displayLang]}</title>
                    <link rel="alternate" hreflang="en" href="https://hnkbd.ca/ourcreations" />
                    <link rel="alternate" hreflang="fr" href="https://hnkbd.ca/fr/ourcreations"/>

                </Helmet>
                <header>
                    <div className='IS_SectionTitle'>
                        <h1>{tt.headerH1[displayLang]}</h1>
                        <h2>{tt.headerH2[displayLang]}</h2>
                        <p>{tt.HeaderP[displayLang]}</p>
                    </div>
                </header>
                <div className='filterBar'>
                    {/* <span>Filtres</span> */}
                    <button id='caselessFilterBtn' onClick={((e)=>{filterKbdType("caseless","caselessFilterBtn","pastCreation")})}>{textDataArray._inStock.filterBtnCaseless[displayLang]}</button>
                    <button id='caseFilterBtn' onClick={((e)=>{filterKbdType("woodenCase","caseFilterBtn","pastCreation")})}>{textDataArray._inStock.filterBtnWoodCase[displayLang]}</button>
                </div>
                <CardContainer 
                    dataArray={dataArray} 
                    addToCart={addToCart}
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    displayCurr={displayCurr}
                    />
            </div>
        </main>
    )
}

export {OurCreations}