import React, { useState, useEffect } from 'react';
import './policies.css';
import { policiesSourceTextENG, policiesSourceTextFR } from '../../Translations/ENG';
import { Helmet } from 'react-helmet';


const PoliciesPage=({textDataArray, displayLang})=>{
    let policiesArray = [];
    displayLang == "ENG"? policiesArray= policiesSourceTextENG.policies : policiesArray= policiesSourceTextFR.policies;
    let tt = textDataArray._policies;
    return(
        <main>
            <div id='policiesPage'>
                <Helmet>
                    <meta name="description" content={textDataArray._headTag.policiesDescription[displayLang]}/>
                    <title>{textDataArray._headTag.policiesTitle[displayLang]}</title>
                    <link rel="alternate" hreflang="en" href="https://hnkbd.ca/policies" />
                    <link rel="alternate" hreflang="fr" href="https://hnkbd.ca/fr/policies"/>
                </Helmet>
                <header id='firstHeader'>
                    <div className='sectionTitle'>
                        <h1>{tt.h1[displayLang]}</h1>
                        <h2>{tt.h2[displayLang]}</h2>
                    </div>
                    <div className='innerText'>
                        <p>{tt.p[displayLang]}</p>
                    </div>
                </header>
                <PolicyContent policiesArray={policiesArray}></PolicyContent>
            </div>
        </main>
    )                     
}


const PolicyContent =({policiesArray})=>{
    // console.log(JSON.stringify(policiesArray));
    return(
        <article>
        {policiesArray.map((policy)=>
            <div className='section' id={policy.name}>
            <header>
                <h1>{policy.name}</h1>
            </header>
            {policy.text.map((paragraph)=>     
            <>     
                {paragraph.h2 ? (
                    <>
                        <h2>{paragraph.h2}</h2>
                        {paragraph.p.map((innerText)=>(
                           <p>{innerText}</p> 
                        ))}
     
                    </>)
                    :(<>
                        {paragraph.p.map((innerText)=>(
                           <p>{innerText}</p> 
                        ))}
                    
                    </>)}
                
            </>
                )}   
            </div>)}
        </article>
    )
}


export{PoliciesPage};
