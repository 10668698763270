import React, { useState, useEffect } from 'react';
import './inStock.css';
import "react-multi-carousel/lib/styles.css";
import { CardContainer } from '../../Components/CardContainer.js';
import { Helmet } from 'react-helmet';

function InStockPage({dataArray, addToCart, textDataArray, displayLang, displayCurr, filterKbdType}){
    const tt = textDataArray._inStock;
    // const [filteredDataArray, setFilteredDataArray]=useState(dataArray)
    // const [appliedFilter, setAppliedFilter] = useState("none") 

    // const filterKbdType=(targetType, btnId)=>{
    //     //manage filter or not filtered
    //     console.log("this.id:" + btnId);
    //     if(targetType == appliedFilter){  //remove filter, managing a second clic on the filter button
    //         setFilteredDataArray(dataArray);
    //         setAppliedFilter("none")
    //         //remove selected classes for CSS
    //         document.querySelector("#"+btnId).classList.remove("selected")
    //     } else {
    //         let filteredArray = dataArray.filter((kbd)=>kbd._caseType == targetType);
    //         setFilteredDataArray(filteredArray);
    //         setAppliedFilter(targetType)
    //         document.querySelector("#"+btnId).classList.add("selected")

    //     }
    // }

    return(
        <main>
            <div id='inStockPage'>
                <Helmet>
                    <meta name="description" content={textDataArray._headTag.instockDescription[displayLang]}/>
                    <title>{textDataArray._headTag.instockTitle[displayLang]}</title>
                    <link rel="alternate" hreflang="en" href="https://hnkbd.ca/instock" />
                    <link rel="alternate" hreflang="fr" href="https://hnkbd.ca/fr/instock" />
                </Helmet>
                <header>
                    <div className='IS_SectionTitle'>
                        <h1>{tt.headerH1[displayLang]}</h1>
                        <h2>{tt.headerH2[displayLang]}</h2>
                        <p>{tt.headerP[displayLang]}</p>
                    </div>
                </header>
                <div className='filterBar'>
                    {/* <span>Filtres</span> */}
                    <button id='caselessFilterBtn' onClick={((e)=>{filterKbdType("caseless","caselessFilterBtn","inStock")})}>{tt.filterBtnCaseless[displayLang]}</button>
                    <button id='caseFilterBtn' onClick={((e)=>{filterKbdType("woodenCase","caseFilterBtn","inStock")})}>{tt.filterBtnWoodCase[displayLang]}</button>
                </div>
                <CardContainer 
                    dataArray={dataArray} 
                    addToCart={addToCart}
                    textDataArray={textDataArray}
                    displayLang={displayLang}
                    displayCurr={displayCurr}
                    />
            </div>
        </main>
    )
}

export {InStockPage}