import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './components.css';
import { CardContainerComponents } from '../../Components/CardContainer.js';
import '../../Components/cardContainer.css'
import { Helmet } from 'react-helmet';

function ComponentsPage({textDataArray, displayLang}){
    const tt = textDataArray._components;
    const ttNav = textDataArray._navBar;

    return(
        <main>
            <div id='componentsPage'>
                <Helmet>
                    <meta name="description" content={textDataArray._headTag.componentsDescription[displayLang]}/>
                    <title>{textDataArray._headTag.componentsTitle[displayLang]}</title>
                    <link rel="alternate" hreflang="en" href="https://hnkbd.ca/components" />
                    <link rel="alternate" hreflang="fr" href="https://hnkbd.ca/fr/components" />
                </Helmet>
                <header>
                <img className='sectionTitle' src ={require('../../images/components_main_2.jpg')} alt={textDataArray._altTexts.componentsBG[displayLang]}/>
                <div className='cardsSection'>
                    <h1>{tt.headerH1[displayLang]}</h1>
                    <h2>{tt.headerH2[displayLang]}</h2>
                    <p>{tt.headerP[displayLang]}</p>
                </div>
                </header>

                    <div id="mainCardContainer">
                        <div id='cardContainer'>
                            {/* gardé hard-codé puisque ne changera jamais*/}
                            <article>
                                <div className="card GatewayComponentsPage">
                                    <img src={require('../../images/woodEssence_main_3.jpg')}  alt={textDataArray._altTexts.componentsWE[displayLang]}/>
                                    <ul><li>{ttNav.subPWE[displayLang]}</li></ul>
                                    <NavLink className="cardNavLink" to="/components/wood" title={ttNav.subPWE[displayLang]}></NavLink>
                                </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/PCB_main.jpg')}  alt={textDataArray._altTexts.componentsPCB[displayLang]}/>
                                <ul><li>{ttNav.subPPCB[displayLang]}</li></ul>
                                <NavLink className="cardNavLink" to="/components/pcb" title={ttNav.subPPCB[displayLang]}></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/plate_main.jpg')}  alt={textDataArray._altTexts.componentsPlate[displayLang]}/>
                                <ul><li> {ttNav.subPPlate[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/plate" title={ttNav.subPPlate[displayLang]}></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/Switches_main_4.jpg')}  alt={textDataArray._altTexts.componentsSwitches[displayLang]}/>
                                <ul><li> {ttNav.subPSwitches[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/switches" title={ttNav.subPSwitches[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/keycaps_main.jpg')}  alt={textDataArray._altTexts.componentsKeycaps[displayLang]}/>
                                <ul><li> {ttNav.subPKeycaps[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/keycaps" title={ttNav.subPKeycaps[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/stabs_001_1.jpg')}  alt={textDataArray._altTexts.componentsStabs[displayLang]}/>
                                <ul><li> {ttNav.subPStabs[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/stabs" title={ttNav.subPStabs[displayLang]} ></NavLink>
                            </div>
                            </article>
                            <article>
                            <div className="card GatewayComponentsPage">
                                <img src={require('../../images/cable_main.jpg')}  alt={textDataArray._altTexts.componentsCables[displayLang]}/>
                                <ul><li> {ttNav.subPCables[displayLang]} </li></ul>
                                <NavLink className="cardNavLink" to="/components/cable" title={ttNav.subPCables[displayLang]} ></NavLink>
                            </div>
                            </article>
                        </div>     
                    </div>
            </div>
        </main>
    )
}

function ComponentsDetailsPage(props){
    // console.log("dans component details page, props dataArray:" + JSON.stringify(props.dataArray));

    return(
        <main>
            <div id='componentsPage'>
                <Helmet>
                    <meta name="description" content={props.subTitle}/>
                    <title>{props.title + props.textDataArray._headTag.componentsDetailSuffix[props.displayLang]}</title>
                    <link rel="alternate" hreflang="en" href={"https://hnkbd.ca/components/"+props.subPath} />
                    <link rel="alternate" hreflang="fr" href={"https://hnkbd.ca/fr/components/" +props.subPath} />
                </Helmet>
                <header>
                    <img className='sectionTitle' id={props.id} src ={props.backgroundPicture} alt={props.altText[props.displayLang]}/>
                    {/* <div className='bgBlurr'> </div> */}
                    <div className='cardsSection'>
                        <h1>{props.title}</h1>
                        <h2>{props.subTitle}</h2>
                        <p>{props.text}
                        </p>
                    </div>
                </header>
                {props.subPath =="wood"? //Show case type filter only for wood components
                <>
                    <div className='filterBar'>
                    <button id='caselessFilterBtn' onClick={((e)=>{props.filterKbdType("caseless","caselessFilterBtn","componentWood")})}>{props.textDataArray._inStock.filterBtnCaseless[props.displayLang]}</button>
                    <button id='caseFilterBtn' onClick={((e)=>{props.filterKbdType("woodenCase","caseFilterBtn","componentWood")})}>{props.textDataArray._inStock.filterBtnWoodCase[props.displayLang]}</button>
                </div>
                </>:<></>}
                <CardContainerComponents 
                    dataArray={props.dataArray} 
                    addToCart={props.addToCart}
                    textDataArray={props.textDataArray}
                    displayLang={props.displayLang}
                    displayCurr={props.displayCurr}
                    />
            </div>
        </main>
    )
}


export {ComponentsPage, ComponentsDetailsPage}